import React from "react"
import Lottie from "react-lottie"
import styled from "styled-components"

import Header from "../components/header"

import path1 from "../assets/Arrow1.svg"
import path2 from "../assets/Arrow2.svg"
import path3 from "../assets/Arrow3.svg"

import icon1 from "../assets/ICON.svg"
import icon2 from "../assets/ICON2.svg"
import icon3 from "../assets/ICON3.svg"
import icon4 from "../assets/ICON4.svg"

const Stepbystep = () => {
  return (
    <>
        	<Top>
        <HowItWorks>HOW IT WORKS</HowItWorks>
        <HowItWorks2>HOW IT WORKS</HowItWorks2>
        <Path1 alt="path1" src={path1} />
        <Path2 alt="path2" src={path2} />
        <Path3 alt="path3" src={path3} />
        </Top>
    <Body>

        <Step1Div>
          <Icon1 alt="search" src={icon1} />

          <HowText1>Step 1: Search</HowText1>
          <HowSubText1>
            Look at high earning potential properties around the world.
          </HowSubText1>
        </Step1Div>
        <Step1Div>
          <Icon1 alt="search" src={icon2} />

          <HowText1>Step 2: Buy</HowText1>
          <HowSubText1>
            Buy shares which split equity and profit of the rental.  100 shares are 1% of profity and equity. 
          </HowSubText1>
        </Step1Div>
        <Step1Div>
          <Icon1 alt="search" src={icon3} />

          <HowText1>Step 3: Earn</HowText1>
          <HowSubText1>
            Receive your payouts from the earnings every month.  Users will make bid on your shares to also buy you out!
          </HowSubText1>
        </Step1Div>
        <Step1Div>
          <Icon1 alt="search" src={icon4} />

          <HowText1>Step 4: Vacation</HowText1>
          <HowSubText1>
            Own the property and stay there for next to nothing or with a serious discount.
          </HowSubText1>
        </Step1Div>
        </Body>
    </>
  )
}

export default Stepbystep

const Body = styled.div`
	margin: 50px 0px 100px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 40px;
	

	@media (max-width: 1332px) {
    margin: 50px 0px 100px 50px;
		grid-template-columns: repeat(2, 1fr);
	}
	@media (max-width: 767px) {
    
		grid-template-columns: repeat(1, 1fr);
	}

    @media (max-width: 300px) {
    margin: 50px 0px 50px;
    grid-template-columns: repeat(1, 1fr);
  }
`

const Top = styled.div`

  height:200px;
	@media (max-width: 1332px) {
		
	}
	@media (max-width: 769px) {
		
	}
`


const Path1 = styled.img`

  transform: matrix(0.88, 0.48, 0.48, -0.88, 0, 0);
  position: relative;
  width: 306.5px;
  height: 230.02px;
  left: 10%;
  top: 120.23px;

     @media screen and (max-width: 1333px) {
  display: none;
}
`

const Path2 = styled.img`
  position: relative;
  width: 286.5px;
  height: 200.02px;
  left: 15%;
  top: 120.23px;

  transform: rotate(6deg);
  transform: matrix(0.88, 0.48, 0.48, -0.88, 0, 0);

    @media screen and (max-width: 1333px) {
  display: none;
}

`
const Path3 = styled.img`
  position: relative;
  width: 316.5px;
  height: 230.02px;
  left: 23%;
  top: 120.23px;

  transform: matrix(0.88, 0.48, 0.48, -0.88, 0, 0);
    @media screen and (max-width: 1333px) {
  display: none;
}
`


const HowItWorks = styled.h2`
  position: relative;
  width: 616px;
  height: 99px;
  left: 100.12px;
  top: 100.33px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 65.7269px;
  line-height: 99px;
  /* identical to box height */

  letter-spacing: 1.56101px;
  text-transform: uppercase;

  color: #000000;

  opacity: 0.04;

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    left: 60.12px;

  }
`

const HowItWorks2 = styled.h2`
  position: relative;
  width: 358px;
  height: 60px;
  left: 100.12px;
  top: 50.63px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36.9714px;
  line-height: 55px;
  letter-spacing: 1.56101px;
  text-transform: uppercase;

  color: #000000;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    left: 60.12px;
  }
`

const HowDiv = styled.div`
  position: absolute;
  width: 1332.53px;
  height: 587.7px;
  left: 264.96px;
  top: 790.33px;
    @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const Step1Div = styled.div`
	max-width: 300px;
	height: 300px;
	position: relative;
	

	display: grid;
	grid-template-rows: 1fr 1fr;

	transition:0.8s cubic-bezier(0.2,0.8,0.2,1);
	&:hover {
		
	}
`

const Icon1 = styled.img`
  position: absolute;
  width: 194.29px;
  height: 194.29px;
  
  top: 0.39px;


`
const HowText1 = styled.h2`
  position: absolute;
  height: 34px;
  left: 16.12%;
  right: 5.18%;
  top: calc(50% - 34px / 2 + 23.57px);

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 24.6476px;
  line-height: 33px;

  /* or 134% */
  color: #1d293f;
    @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const HowSubText1 = styled.p`
  position: absolute;
  left: 1.37%;
  right: 4.53%;
  top: 68.65%;
  bottom: 8.65%;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16.4317px;
  line-height: 23px;
  /* or 138% */

  color: #737b7b;
    @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  	left: 10.37%;
  	right: 10.37%;
  }
`


const Step2Div = styled.div`
  position: absolute;
  width: 339.31px;
  height: 307.27px;
  left: 499.35px;
  top: 991.33px;
    @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const Icon2 = styled.img`
  position: absolute;
  width: 94.29px;
  height: 94.29px;
  left: 53.31px;
  top: 21.39px;
    @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const Step3Div = styled.div`
  position: absolute;
  width: 369.71px;
  height: 282px;
  left: 825px;
  top: 1033px;
    @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`
const Step4Div = styled.div`
  position: absolute;
  width: 317.87px;
  height: 286.28px;
  left: 1180.62px;
  top: 1091.75px;
    @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`